import React from 'react';
import { Link } from 'gatsby'
import Article from '../../components/Article'
import "../../components/App.css"
import "../../components/ArticleContent.css"

export default function SolutionAMD() {
	
	return (
		<Article
			title='Macular degeneration (a disease of eye’s retina)'
			takeaway_title='Measures to cure Macular Degeneration'
			page_id='Solution - AMD'
			read_time='10 mins'
			author='Brij Bhushan'
			image1='/amsler-grid2.jpg'
			image1_description='Amsler Grid appears a bit wavey if one is suffering from Macular Degeneration'
			image2='/drusen.jpg'
			image2_description='Drusen is a layer under retina built by protein accumulation'
			toggle_article='Blog'
			toggle_url='/article/blog_amd'
			related_article='Blog : AMD'
			takeaway_data={TakeawayData}
			related_articles={RelatedArticles}
		>
				<div className='article_container'>
					<p>
						Retina is the layer at the back of the eye which helps us to see. Macula is the central part of the eye's retina. In old age, few people are unable to see clearly from the centre of the eye. First the images blur and later a dark spot may appear in the centre which enlarges as the disease progresses. This is called Macular Degeneration. Since eyes work together, therefore when one eye gets this problem it may not be noticed because the other eye is still able to see. Amsler grid can be used to test for Macular Degeneration. Amsler grid is a set of vertical and horizontal straight lines intersecting each other. Eyes can be tested one at a time. So, one can test an eye by closing the other eye and looking at it. If any of the line appears curved or one sees a dark spot, then it is a sign that the eye has Macular Degeneration. <span className="super">[1], [2]</span>
					</p>
					<p>
						Macular Degeneration is a leading cause of vision loss in developed as well as developing countries. It destroys the sharp central vision which affects reading & driving. It is often referred as Age-related Macular Degeneration. It is often abbreviated as AMD or ARMD. It is categorized as Dry-AMD and Wet-AMD. In Dry-AMD, a layer of protein (referred as Drusen) builds up underneath the retina. In Wet-AMD (also called neovascular AMD or exudative AMD), few blood vessels start growing into the layer of Drusen towards the retina, which can eventually leak blood on the retina. Often, the Dry-AMD condition deteriorates into Wet-AMD. <span className="super">[1], [3]</span>
					</p>
					<p>
						The main trigger of the disease is the depositing of Drusen underneath the retina. It is caused by oxidative stress. Oxidative stress is the result of excess of ‘Free Radicals’ in the body. Free Radicals are a reactive distortion of Oxygen molecules. Free Radicals are produced in the body under medical conditions like chronic muscle inflammation, severe infection. Smoking also generates Free Radicals. Few environmental factors are also responsible for Free Radicals, like pollution, excess UV radiation or pesticides in food. Few drugs & food additives also cause an increase in Free Radicals. Body produces antioxidants to neutralize the Free Radicals. However, when antioxidants are short in supply as compared to the Free Radicals, that leads to oxidative stress on human body cells. <span className="super">[4], [5]</span>
					</p>
					<p>
						Popular medical treatment for Dry-AMD is through supplementing the diet with multivitamins that contain Vitamin A,C & E, along with Omega-3 fatty acids, Zinc and other trace minerals. Popular medical treatment for Wet-AMD is administering intravitreal injections of VEGF inhibitors. Intravitreal injection means the medicine is directly injected into the eye. The procedure is done under local anesthesia inside an operation theatre. VEGF stands for Vascular Endothelial Growth Factor. It is a protein generated by body cells that stimulates formation of blood vessels. VEGF inhibitors are intended to neutralize the protein. This reduces making of new blood vessels in Drusen deposits and hence slows down leaking of blood into the retina. Examples of VEGF inhibitors are Ranibizumab (brand: Lucentis & Accentrix), Bevacizumab (brand: Avastin), Aflibercept (brand: Eylea & Zaltrap) and Faricimab. Use of Bevacizumab is considered off-label because it is not formally approved for the treatment due to lack of sufficient trial data. However, the available research finds it equally effective as Ranibizumab. Bevacizumab is popular because it is much cheaper than Ranibizumab in cost. The business of VEGF inhibitor injections is dominated by 6 companies worldwide. These are Genentech, Novartis, Bausch Health, Regeneron, Roche & Regenxbio. <span className="super">[6], [7], [8], [9], [10]</span>
					</p>
					<h3 className='article_heading'>How to deal with Macular Degeneration?</h3>
					<p>
						Once a person notices the problem in central vision in any of the eyes, he should verify it is with the Amsler grid test. If the test indicates Macular Degeneration then consult an ophthalmologist and follow his advice. In parallel, following measures can be taken which will help in maintaining the vision gains for a longer period:
						<ol className='article_list'>
							<li>Add fruits & vegetables in the diet</li>
    						<li>Drink saffron tea every morning (empty stomach)</li>
    						<li>No sugar or sugar-based products</li>
    						<li>Daily 14 hrs fasting</li>
    						<li>Quit smoking, if you do</li>
    						<li>Stop using refined seed oils in cooking</li>
    						<li>Regular exercise</li>
    					</ol>
					</p>
					<h3 className='article_heading'>Add fruits & vegetables in the diet</h3>
					<p>
						The key factor behind Macular Degeneration is Oxidative Stress, due to Free Radicals. Vitamin A, C & E, Iron, Zinc, Copper, Carotenoids (Lutein & Zeaxanthin) and Omega-3 fatty acids play important roles in neutralizing the free radicals by production of antioxidants within the body. While doctors prescribe multivitamins to supplement these nutrients, it is a better idea to also add natural sources of these nutrients in the diet. The natural sources are fruits and vegetables. <span className="super">[6]</span>
					</p>
					<p>
						Fruits should be consumed before meals and shouldn’t be consumed along with meals or after meals. Fruits get digested much faster than cooked food. If one consumes fruits with or after cooked food, then the fruits are exposed to more amounts of stomach acid and that reduces its nutrients. Some of the fruits that help in curing Macular Degeneration are Grapes, Apples, Oranges, Mangoes, Papaya, Guava, Strawberries, Kiwi, Apricot and Grapefruit. Since different fruits have different sets of nutrients, therefore one should have a variety of the fruits in their diet, instead of consuming the same fruit daily. <span className="super">[11], [12]</span>
					</p>
					<p>
						Different vegetables are consumed differently. While Cucumber & Lettuce are consumed raw, Sweet potatoes & Broccoli are preferred steamed/boiled, and others like Spinach & Cabbage are cooked longer. While it is good to appropriately cook the vegetables, they shouldn’t be overcooked. Cooking them sufficiently makes them easy to digest but overcooking neutralizes their nutrients. That’s why, pressure cooking vegetables at high temperatures is not advised. Some of the vegetables that help in curing Macular Degeneration are Spinach, Broccoli, Corn, Cucumber, Lettuce, Pea, Turnip, Carrot, Pumpkin, Sweet potatoes, Tomatoes, Red pepper & Green pepper. <span className="super">[12], [13]</span>
					</p>
					<p>
						Apart from that, adding (soaked) walnuts, (soaked) almonds, flaxseeds, chia seeds, chickpeas, milk & curds in the diet are also helpful. <span className="super">[12]</span>
					</p>
					<h3 className='article_heading'>Drink saffron tea every morning</h3>
					<p>
						Oxidative stress is the key factor behind Macular Degeneration. Saffron can be added to the diet to manage that. It is a potent antioxidant which neutralizes the free radicals in the body. This prevents further Drusen accumulation underneath the retina and further deterioration of the condition. <span className="super">[14]</span>
					</p>
					<p>
						Best way to consume saffron to leverage its antioxidant properties is to crush 10-15 threads of saffron (approx. 30 mg) in mortar & pestle, and make saffron tea by adding it to a cup of hot water. Saffron tea should be consumed in the morning with an empty stomach. For best results, one shouldn’t consume anything for at least 30 mins after having the Saffron tea. <span className="super">[15]</span>
					</p>
					<h3 className='article_heading'>No sugar or sugar-based products</h3>
					<p>
						When one consumes refined white sugar, it converts into a high amount of glucose during digestion. Human body can easily absorb the sugar from natural sources like fruits, grains, etc. but to absorb the glucose generated from refined white sugar it requires high secretion of insulin from pancreas. This leads to a condition called Insulin Resistance. Insulin Resistance leads to high levels of glucose in blood vessels, which results in inflammation in them and higher chances of blood leaking through them into the retina. <span className="super">[16]</span>
					</p>
					<p>
						Therefore, one should stop consuming refined sugar in any form. This means not eating any food item which has sugar in the ingredients, like biscuits/cookies, cakes, soft drinks, ice-creams, chocolates, candies, etc. However, one can eat sweet fruits like mangoes, oranges, dates, etc. but in moderation. One can also consume food items prepared with jaggery or honey but in moderation. <span className="super">[17]</span>
					</p>
					<h3 className='article_heading'>Daily 14 hrs fasting</h3>
					<p>
						Autophagy is a mechanism of the body that recycles its misfolded proteins. It is one of the capabilities of the human body that allows it to survive on unused resources available within the body, when it is short of supply from food intake. When used wisely, autophagy can help get rid of the unnecessary deposits in various parts of the body. This can also help in handling drusen deposits underneath the retina. <span className="super">[18]</span>
					</p>
					<p>
						To trigger autophagy one has to daily observe fasting for 14 hrs. Here, fasting refers to not eating anything during the period. One can consume only water during the period. This may not be as difficult as it may sound because the fasting can be done while you are sleeping. If you eat dinner at 8 PM and then have breakfast at 10 AM the next morning, then it is sufficient to trigger the autophagy. If you incorporate this routine in your lifestyle, then it can help in addressing the root of the macular degeneration itself. <span className="super">[19]</span>
					</p>
					<h3 className='article_heading'>Quit smoking, if you do</h3>
					<p>
						Smoking increases oxidative stress in the body because cigarette smoke is a strong oxidant and it quickly increases free radicals in the body. Therefore, if one smokes then he/she should immediately stop smoking to stop further deterioration in the condition. <span className="super">[5]</span>
					</p>
					<h3 className='article_heading'>Stop using refined seed oils in cooking</h3>
					<p>
						It has been observed that Omega-6 fatty acids are essential for health. However, the body requires a balance between Omega-6 fatty acids & Omega-3 fatty acids. Diet that has more Omega-6 fatty acids & low Omega-3 fatty acids, creates an imbalance in omega-6/ omega-3 PUFA ratio. PUFA stands for PolyUnsaturated Fatty Acids. Increase in this ratio has been correlated with development of Macular Degeneration in various studies. <span className="super">[20], [21]</span>
					</p>
					<p>
						Different oils & fats which are consumed in diet are categorized with respect to its key constituent. The key categories & their examples are as follows:
						<ol className='article_list'>
							<li>Omega-3 PUFA Oils : Fish oil, Cod liver oil, Flaxseed oil</li>
    						<li>Omega-6 PUFA Oils : Grape seed oil, Sunflower oil, Groundnut oil, Safflower oil, Brazil nut oil, Sunflower oil, Sesame oil, Corn oil, Cottonseed oil, Palm oil, Rice bran oil, Soybean oil</li>
    						<li>MUFA Oils : Olive oil</li>
    						<li>SFA Oils/Fats : Desi Ghee, Butter, Coconut oil</li>
    						<li>Trans Fats : Partially hydrogenated vegetable oils</li>
    					</ol>
					</p>
					<p>
						Trans Fats are bad for the heart, so these are first to be scored out. Next to be scored out are Omega-6 PUFA Oils. Therefore, one should prefer using Desi Ghee, Butter, Coconut oil or Olive oil in diet over other oils. <span className="super">[20], [21], [22], [23]</span>
					</p>
					<h3 className='article_heading'>Regular exercise</h3>
					<p>
						People who exercise regularly have been observed to have lower odds of suffering from Macular Degeneration. Therefore staying active throughout life can be characterized to have a protective effect on its onset and/or progression. <span className="super">[24]</span>
					</p>
					<h3 className='article_heading'>Conclusion</h3>
					<p>
						Fluid accumulation underneath the retina is a serious problem. If it is not addressed in time, it can result in loss of the vision of the eye. Therefore, if one is diagnosed with Macular Degeneration, then he/she should follow the advice of the doctor. In parallel, he/she should make the diet & lifestyle changes mentioned here, so that in future his/her condition can stabilize. 
					</p>
					<p>
						One can refer to my blog <Link to='/article/blog_amd'>here</Link>, to learn about my experiences with the diet & lifestyle changes mentioned above.
					</p>
					<h3 className='article_heading'>References</h3>
					<ol className="reference_list">
						<li>
							Age-Related Macular Degeneration. NIH - National Eye Institute. June 22, 2021. <Link to='https://www.nei.nih.gov/learn-about-eye-health/eye-conditions-and-diseases/age-related-macular-degeneration'>https://www.nei.nih.gov/learn-about-eye-health/eye-conditions-and-diseases/age-related-macular-degeneration</Link>
						</li>
						<li>
							Liz Segre. Amsler Grid: Macular Degeneration Test (Screening). All about vision - Eye care - Vision resources. May 2021. <Link to='https://www.allaboutvision.com/conditions/amsler-grid.htm'>https://www.allaboutvision.com/conditions/amsler-grid.htm</Link>
						</li>
						<li>
							Bright Focus Foundation. Age-Related Macular Degeneration: Facts & Figures. January 5, 2019. <Link to='https://www.brightfocus.org/macular/article/age-related-macular-facts-figures'>https://www.brightfocus.org/macular/article/age-related-macular-facts-figures</Link>
						</li>
						<li>
							Beatty S, Koh H, Phil M, Henson D, et. al. The role of oxidative stress in the pathogenesis of age-related macular degeneration. Survey of Ophthalmology. 2000 Sep-Oct. 45(2):115-34. <Link to='https://doi.org/10.1016/s0039-6257(00)00140-5'>https://doi.org/10.1016/s0039-6257(00)00140-5</Link>
						</li>
						<li>
							Bellezza I. Oxidative Stress in Age-Related Macular Degeneration: Nrf2 as Therapeutic Target. Frontiers in pharmacology, Vol:9, 1280. Nov 2018. <Link to='https://doi.org/10.3389/fphar.2018.01280'>https://doi.org/10.3389/fphar.2018.01280</Link>
						</li>
						<li>
							Waugh N, Loveman E, Colquitt J, et al. Treatments for dry age-related macular degeneration and Stargardt disease: a systematic review. Southampton (UK): NIHR Journals Library; May 2018. (Health Technology Assessment, No. 22.27.) Chapter 6, Nutritional interventions in dry age-related macular degeneration. <Link to='https://www.ncbi.nlm.nih.gov/books/NBK500492/'>https://www.ncbi.nlm.nih.gov/books/NBK500492/</Link>
						</li>
						<li>
							Khoo, H. E., Ng, H. S., Yap, W. S., et. al. Nutrients for Prevention of Macular Degeneration and Eye-Related Diseases. Antioxidants (Basel, Switzerland), 8(4), 85. 2019.  <Link to='https://doi.org/10.3390/antiox8040085'>https://doi.org/10.3390/antiox8040085</Link>
						</li>
						<li>
							Treatments for Wet AMD (Advanced Neovascular AMD). NIH - National Eye Institute. June 22, 2021. <Link to='https://www.nei.nih.gov/learn-about-eye-health/eye-conditions-and-diseases/age-related-macular-degeneration/wet-amd-type-late-age-related-macular-degeneration-read-about-treatments-wet-amd-anti-vegf'>https://www.nei.nih.gov/learn-about-eye-health/eye-conditions-and-diseases/age-related-macular-degeneration/wet-amd-type-late-age-related-macular-degeneration-read-about-treatments-wet-amd-anti-vegf</Link>
						</li>
						<li>
							Reena Mukamal. Comparison of Anti-VEGF Treatments for Wet AMD. American Academy of Ophthalmology. Feb. 03, 2020. <Link to='https://www.aao.org/eye-health/diseases/avastin-eylea-lucentis-difference'>https://www.aao.org/eye-health/diseases/avastin-eylea-lucentis-difference</Link>
						</li>
						<li>
							Macular degeneration treatment market - Growth, Trends, COVID-19 impact, and forecast (2021 - 2026). Morder Intelligence. <Link to='https://www.mordorintelligence.com/industry-reports/macular-degeneration-treatment-market'>https://www.mordorintelligence.com/industry-reports/macular-degeneration-treatment-market</Link>
						</li>
						<li>
							Jayne Leonard. Tips for better digestion - how long does it take? Medical news today. October 1, 2017. <Link to='https://www.medicalnewstoday.com/articles/319583'>https://www.medicalnewstoday.com/articles/319583</Link>
						</li>
						<li>
							Harvard Health Publishing. Top foods to help protect your vision. Staying healthy. August 01, 2013. <Link to='https://www.health.harvard.edu/staying-healthy/top-foods-to-help-protect-your-vision'>https://www.health.harvard.edu/staying-healthy/top-foods-to-help-protect-your-vision</Link>
						</li>
						<li>
							VICTORIA FRANKEL. Raw vs. Cooked - What’s the best way to eat your vegetables? Viome. Jan 20, 2020. <Link to='https://www.viome.com/blog/raw-vs-cooked-whats-best-way-eat-your-vegetables'>https://www.viome.com/blog/raw-vs-cooked-whats-best-way-eat-your-vegetables</Link>
						</li>
						<li>
							Lashay, A., Sadough, G., Ashrafi, E., et. al. Short-term Outcomes of Saffron Supplementation in Patients with Age-related Macular Degeneration: A Double-blind, Placebo-controlled, Randomized Trial. Medical hypothesis, discovery & innovation ophthalmology journal, 5(1), 32–38. 2016. <Link to='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5342880/'>https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5342880/</Link>
						</li>
						<li>
							Michael Greger. What’s the right dose of saffron? Nutrition Facts. November 8, 2012. <Link to='https://nutritionfacts.org/questions/how-do-you-dose-saffron/'>https://nutritionfacts.org/questions/how-do-you-dose-saffron/</Link>
						</li>
						<li>
							Rowe CW, Haider AS, Viswanathan D, et. al. Insulin resistance correlates with maculopathy and severity of retinopathy in young adults with Type 1 Diabetes Mellitus. Diabetes Research and Clinical Practice. Sep 2017;131:154-160. <Link to='https://doi.org/10.1016/j.diabres.2017.06.022'>https://doi.org/10.1016/j.diabres.2017.06.022</Link>
						</li>
						<li>
							K. R. Srikantha Murthy, Vagbhata’s Astanga Hrdayam, Volume-1, Chapter-10, Chowkhamba Krishnadas Academy, Varanasi, Reprint 2019.
						</li>
						<li>
							Kaarniranta K. Autophagy--hot topic in AMD. Acta Ophthalmol. Jun 2010; 88(4):387-8. <Link to='https://onlinelibrary.wiley.com/doi/10.1111/j.1755-3768.2009.01840.x'>https://onlinelibrary.wiley.com/doi/10.1111/j.1755-3768.2009.01840.x</Link>
						</li>
						<li>
							[19] Cuervo A. M. (2008). Autophagy and aging: keeping that old broom working. Trends in genetics : TIG, 24(12), 604–612. <Link to='https://doi.org/10.1016/j.tig.2008.10.002'>https://doi.org/10.1016/j.tig.2008.10.002</Link>
						</li>
						<li>
							Ho Hang Leung, Alex LK. Ng, Thierry Durand, et. al. Increase in omega-6 and decrease in omega-3 polyunsaturated fatty acid oxidation elevates the risk of exudative AMD development in adults with Chinese diet. Free Radical Biology and Medicine. Volume 145. 2019, Pages 349-356, <Link to='https://hal.archives-ouvertes.fr/hal-02624040/file/HAL2019_NPD_neuroP_age%20related%20DMA_Jetty_LeungFRBM2019.pdf'>https://hal.archives-ouvertes.fr/hal-02624040/file/HAL2019_NPD_neuroP_age%20related%20DMA_Jetty_LeungFRBM2019.pdf</Link>
						</li>
						<li>
							Abeba Haile Mariamenatu, Emebet Mohammed Abdu, Overconsumption of Omega-6 Polyunsaturated Fatty Acids (PUFAs) versus Deficiency of Omega-3 PUFAs in Modern-Day Diets: The Disturbing Factor for Their “Balanced Antagonistic Metabolic Functions'' in the Human Body, Journal of Lipids, vol. 2021, Article ID 8848161, 15 pages, 2021. <Link to='https://doi.org/10.1155/2021/8848161'>https://doi.org/10.1155/2021/8848161</Link>
						</li>
						<li>
							Cougnard-Grégoire, A., Merle, B. M., Korobelnik, J. F., et. al. (2016). Olive Oil Consumption and Age-Related Macular Degeneration: The Alienor Study. PloS one, 11(7), e0160240. <Link to='https://doi.org/10.1371/journal.pone.0160240'>https://doi.org/10.1371/journal.pone.0160240</Link>
						</li>
						<li>
							Wikipedia. Vegetable oil. <Link to='https://en.wikipedia.org/wiki/Vegetable_oil'>https://en.wikipedia.org/wiki/Vegetable_oil</Link>
						</li>
						<li>
							McGuinness MB, Le J, Mitchell P, et. al. Physical Activity and Age-related Macular Degeneration: A Systematic Literature Review and Meta-analysis. American Journal of Ophthalmology. Aug 2017; 180:29-38. <Link to='https://doi.org/10.1016/j.ajo.2017.05.016'>https://doi.org/10.1016/j.ajo.2017.05.016</Link>
						</li>
					</ol>
				</div>
		</Article>
	);
}

const TakeawayData = [
	{
		takeaway: 'Add fruits & vegetables in the diet',
	},
	{
		takeaway: 'Drink saffron tea every morning (empty stomach)',
	},
	{
		takeaway: 'No sugar or sugar-based products',
	},
	{
		takeaway: 'Daily 14 hrs fasting',
	},
	{
		takeaway: 'Quit smoking, if you do',
	},
	{
		takeaway: 'Stop using refined seed oils in cooking',
	},
	{
		takeaway: 'Regular exercise',
	},
];

const RelatedArticles = [
	{
		title: 'Blog - AMD',
		path: '/article/blog_amd',
	},
];

